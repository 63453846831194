.order-details-container {
    padding: 0px 8px;

    .collapse-container {
        margin: 20px 0px;
    }
}

.edit-order-header-title {
    font-size: 12px;
    color: #818181;
    font-weight: 700;
    position: absolute;
    margin-top: -12px !important;
    margin-left: 2px !important;
}

.pl-8 {
    padding-left: 8px;
}

.accordion-heading {
    color: #595959;
    font-size: 13px;
    font-weight: bold;
}

.product-specs-collapse {
    .ant-collapse-header {
        padding: 0px 12px !important;
    }
}
.Exclamation-icon {
    color: #FF0000;
    font-size: 18px;
}
.check-icon {
    color: #48AA44;
    font-size: 18px;
}

.tag-close-icon {
    font-size: 12px; 
    color: #000; 
    margin-left: 2px
}

.empty-table-container {
    margin: 100px 0px;
}

.filter-chips-view {
    color: #000;
    background-color: #fff;
    border: 1px solid #D9D9D9;
    border-radius: 2px;
    opacity: 1;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 500;
}

.filter-chips-active {
    color: #fff;
    background-color: #002c8c;
    border: 1px solid #002c8c;
    border-radius: 2px;
    opacity: 1;
    padding: 2px 6px;
    font-size: 12px;
    font-weight: 500;
}

.refresh-icon {
    font-size: 15px;
}

.input-list-container {
    max-height: 350px;
    height: 283px;
    overflow-y: auto;
}

.no-product-data thead {
    th:nth-child(3) {
        width: 10rem;
    }
    tr th.ant-table-selection-column:first-child {
        width: 3rem;
        text-align: left;
    }
} 



.edit-order-flag-model {
    .ant-modal-content {
        .ant-modal-footer {
            text-align: start;
            display: flex;
            justify-content: start;
            flex-direction: row-reverse;
            gap: 8px;
            margin-top: 0px;
        }
        thead.ant-table-thead th, tbody.ant-table-tbody td {
            padding: 10px;
        }
    }
    .ant-modal-header {
        margin-bottom: 0;
    }
    .ant-modal .ant-modal-footer {
        text-align: left;
        margin-top: 0;
    }
.ant-table {
    th, td {
        padding: 8px !important;
    }
}
} 

.order-details-container .ant-select.ant-select-disabled .ant-select-selection-item {
    color: #000000 !important;
}

.order-flags .ant-table-content .ant-table-tbody .ant-table-cell {
    cursor: pointer;
}

.switch-top {
    position: relative;
    bottom: 1px;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent !important;
};

.no-padding-antd-button {
    .ant-btn {
       padding: 0px !important;
    }
}

.validation-msg {
    font-size: 12px;
    font-weight: 400;
}