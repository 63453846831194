.status-select-container {

    .ant-select-selector {
        color: inherit !important;
        background-color: inherit !important;
    }

    .ant-select-arrow {
        color: inherit !important
    }

    .ant-select-selection-item {
        color: inherit !important
    }

    .ant-select-selection-search {
        color: inherit !important
    }
    .ant-select-single.ant-select-sm .ant-select-selector {
        color: inherit !important
    }

    .ant-select-single .ant-select-sm .ant-select-open, .ant-select-selection-item {
        color: inherit !important;
    }
    
    .ant-select-selection-search {
        color: inherit !important;
    }
    
    .ant-select-selection-item {
        color: inherit !important;
    }
    
    .ant-select-single.ant-select-sm .ant-select-selector, .ant-select-arrow {
        color: inherit !important;
    }
    
    .ant-select-single .ant-select-selector, .ant-select-arrow {
        color: inherit !important;
    }
    
    .ant-select-selector, .ant-select-focused, .ant-select-arrow, .ant-select-open {
        color: inherit;
    }
}