.ant-menu .ant-menu-item .header-menu-items, .ant-menu .ant-menu-submenu .header-menu-items {
    font-size: 20px;
}

.content-container {
    margin: 10px;
    min-height: 280px;
    max-height: calc(100vh - 125px);
    overflow-y: auto;
    padding: 5px 10px;
}

.card-container .listing-container .ant-card-body {
    height: calc(100vh - 225px);
    padding: 15px;
    overflow-y: auto;
}

.card-container .ant-card-body p, .card-container .ant-card-body ul {
    margin-top:0;
    margin-bottom: 5px;
}

.card-container .ant-card-body p:last-child {
    margin-bottom: 0;
}

.listing-container, .grids-container {
    margin: 10px;
}

.card-container .grids-container .ant-card-body {
    max-height: calc(30vh - 8px);
    min-height: 165px;
    overflow-y: auto;
    padding: 5px 15px 15px;
}

/*Metrics*/
.grids-container .ant-card-head {
    border: none;
    min-height: 40px;
    padding: 0 15px;
}

.grid-box {
    color: #ffffff;
    padding: 10px;
    margin-bottom: 10px;
}

.grid-box .count-value {
    margin-bottom: 0;
    margin-top: 5px;
}

/*Alerts*/
.list-elements {
    margin-bottom: 5px;
}
.date-label label {
    font-size: 12px;
    color: #aaaaaa;
}
.alerts-elements a {
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: underline;
}
.dashboard-sidebar-menu .ant-menu-item-selected {
    background: #184599;
    color: #ffffff;
}