iframe#webpack-dev-server-client-overlay{display:none!important}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

body {
  background-color: #F8F9FB;
  overflow: hidden;
  margin: 0;
}

/*Flex utility CSS*/
.justify-right {
  justify-content: right;
}

.d-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.justify-between{
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: start;
}

.align-end {
  align-items: end;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

/*Margin utility CSS*/
.mr-15 {
  margin-right: 15px;
}

.mt-5 {
  margin-top: 5px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-16 {
  margin-top: 16px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-32 {
  margin-top: 32px;
}

.m-0 {
  margin: 0 !important;
}

.ml-10 {
  margin-left: 10px;
}

.ml-15 {
  margin-left: 15px;
}

.p-0 {
  padding: 0px !important;
}

/*Text utility CSS*/
.txt-bold {
  font-weight: bold;
}

.text-right {
  text-align: right;
}

a, .txt-base-color {
  color: #002c8c;
}

.text-color-gray {
  color: #595959;
}

/*Padding utility CSS*/
.pl-5 {
  padding-left:5px;
}

.p-15 {
  padding:15px;
}

.p-8 {
  padding: 8px;
}

.p-16 {
  padding: 16px;
}

.px-15{
  padding-left: 15px;
  padding-right: 15px;
}

.pb-8{
  padding-bottom: 8px;
}

.pt-8{
  padding-top: 8px;
}

/*Common CSS*/
.search-icon-style {
  color: rgba(0,0,0,.45);
}

.tab-container {
  margin:15px;
}

.ant-form-item-row .ant-form-item-label .ant-form-item-required {
  font-weight:600;
  text-transform: uppercase;
}

.ant-form-item-row .ant-form-item-label .ant-form-item-required:before {
  content: " " !important;
}

.ant-form-item-row .ant-form-item-label .ant-form-item-required:after {
  content: "*";
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun,sans-serif;
  line-height: 1;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.required:after {
  content:" *";
  color: #FF0000;
}

.error {
  font-size: 12px;
  color: #FF0000;
  margin: 4px 0px 0px 1px;
}

.error-text {
  color: #FF0000;
}

.cursor-pointer {
  cursor: pointer;
}

.popup-action {
  text-align: right;
}

.error-notification .ant-notification-notice-icon, 
.success-notification .ant-notification-notice-icon {
    display: none;
}

.error-notification .ant-notification-notice-message .title.title-only {
    color:#FF0000;
    margin-right: 15px;
}

.error-notification .ant-notification-notice-content .ant-notification-notice-message, 
.success-notification .ant-notification-notice-content .ant-notification-notice-message {
    margin-inline-start: 0px;
}

.success-notification .ant-notification-notice-message .title.title-only {
    color: #5CB85C;
    margin-right: 15px;
    
}

.success-notification .ant-notification-notice-message{
  color: #5CB85C;
  margin-right: 22px;
  padding-left: 10px;
  text-align: center;

}
.success-notification .ant-notification-notice-description{
  color: #5CB85C;
}

.ant-notification-notice .ant-notification-notice-with-icon .ant-notification-notice-description{
  margin-inline-start: 0px;
  margin-top: 1px;
  font-size: 16px ;
  padding-left: 10px;
}

.ant-notification-notice.success-notification  {
    background: #DFF0D8;
    border: 1px solid #5CB85C;
    width: max-content;
}

.ant-notification-notice.error-notification  {
    background: #F2DEDE;
    border: 1px solid #EA2F25;
    width: max-content;
}

.ant-notification-notice.error-notification .ant-notification-notice-message,
.ant-notification-notice.success-notification .ant-notification-notice-message {
  margin-bottom: 0px !important;
}

.ant-notification-notice.success-notification .ant-notification-notice-close {
    background: #5CB85C;
    border-radius: 50% !important;
    color: #ffffff !important;
}

.ant-notification-notice.error-notification .ant-notification-notice-close  {
    background: #EA2F25;
    border-radius: 50% !important;
    color: #ffffff !important;
}

.link-inherit {
  color: inherit;
  text-decoration: inherit;
}

.w-100 {
  width: 100%;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-15 {
  margin-bottom: 15px;
}

.text-uppercase {
  text-transform: uppercase;
}

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	background-color: rgba(5, 5, 5, 0.3);
}

/* Start - CSS for quick view icon button for active and inactive states */
.eye-icon-style {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.eye-icon-inactive {
  color: #184599;
  background-color: #fff;
}

.eye-icon-active {
  color: #fff;
  background-color: #184599;
}
/* End - CSS for quick view icon button */

.top-120 {
    top: 120px;
}

.center-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 99;
  width: 100%;
  height: 100%;
}

.display-none {
  display: none !important;
}

.pointer-none {
  pointer-events: none;
}

span.ant-select-selection-placeholder, span.ant-select-selection-item {
    font-size: 14px;
}

.ant-btn-primary {
  box-shadow: none !important;
}

.ant-input-disabled {
  color: #000000 !important
}

.order-flags .ant-table-body .ant-table-cell {
    cursor: pointer;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: transparent !important;
}

.visibility-hidden {
  visibility: hidden;
}

.z-index-99 {
  z-index: 99;
}

.price-tiers-listing .ant-table-content th, .price-tiers-listing .ant-table-content td {
    padding: 3px !important;
}

.line-height-inherit {
  line-height: inherit !important;
}

.disabled-container {
  pointer-events: none;
  opacity: 0.8;
}

.error-popover-wrapper {
  .ant-popover-content {
      border: 1px solid red !important;
  }

  .ant-popover-inner {
      border-radius: 0px !important;
  }

  .ant-popover-arrow::after {
      background: red;
  }
}

.wrap-text {
  text-wrap: wrap;
}

.disabled-row {
  background-color: #dcdcdc;
  pointer-events: none;
}

@-moz-document url-prefix() {
 .flex-baseline {
    display: flex;
    align-items: baseline;
 }
}