.quick-view-container {
    min-width: 810px;
    // max-height: 400px;
    // overflow-y: auto;
    
    .header {
        background-color: #F5F5F5;
        padding: 8px;
    }

    .key-style {
        color: #595959;
        font-size: 13px;
        font-weight: 600;
    }

    .padding-8-hr {
        padding: 0px 8px;
    }

    .empty-view-height {
        height: 400px;
    }

    .ant-table-thead .ant-table-cell {
        background-color: #F5F5F5;
    }

    .product-list-table {
        max-height: 200px;
        overflow-y: auto;
    }

    .ant-empty-normal {
        margin-block: 8px;
    }
}