@mixin tag-container($borderColor) {
    border: 1px solid $borderColor !important;
}

@mixin tag-content($color, $fontSize) {
    color: $color;
    font-size: $fontSize;
}

//green status tag 
.tag-container-green {
    @include tag-container(#318311);
}

.tag-content-green {
    @include tag-content(#318311, 10);
}

//transparent assignedTo titles tag
.tag-container-transparent {
    margin-inline-end: 4px;
    padding-inline: 2px;
}

.tag-content-transparent {
    @include tag-content(#000, 13px);
    font-weight: normal;
}