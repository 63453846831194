.layout-header-style{
    text-align: left;
    padding: 8px 25px;
    background-color: #ffffff;
    height: auto;
    line-height: initial;
}
.main-header .ant-menu-horizontal {
    line-height: 50px;
}

.sider-layout-style, .ant-layout-sider.sider-style{
    height: calc(100vh - 115px);
    background-color: #ffffff;
}

.main-header {
    border: 2px solid rgba(168, 176, 189, 0.2);
}